.user-slide {
    background: linear-gradient(#CED3E1, #F3F3F3) !important;
    border-radius: 12px;
    transform: scale(.9);
    transition: all .5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    overflow-y: auto;
    padding: 20px;
}

@media (min-width:768px) {
    .swiper-slide-active+div .user-slide {
        background: linear-gradient(#2A4EC9, hsl(227, 70%, 64%)) !important;
        color: white !important;
        transform: scale(1);
        transition: all .5s
    }
}

@media (max-width:768px) {
    .swiper-slide-active .user-slide {
        background: linear-gradient(#2A4EC9, hsl(227, 70%, 64%)) !important;
        color: white !important;
        transform: scale(1);
        transition: all .5s
    }
}

.user-image {
    border-radius: 50px;
}

.swiper-slide-active+div .user-image {
    border: 5px solid #2A4EC9;
}

.about-us {
    width: 300px;
}

.sign {
    width: 150px;
}

.bg-primary {
    background-color: #011142;
}

.primaryBtn {
    background: linear-gradient(#2A4EC9, black);
    border: 1px solid white;
    color: white;
    outline: none;
    display: inline-flex;
    justify-self: center;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;
}

.bb:hover {
    border-bottom: 1px solid #2A4EC9;
    cursor: pointer;
}

.position-relative {
    position: relative;
}

.social-media-icons {
    color: white;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    align-items: center;
    padding: 8px;
    display: inline-flex;
    justify-content: center;
    gap: 8px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: #2a4ec985;

    @media (min-width:992px) {
        background: #2A4EC9;
        display: flex;
        z-index: 1;
        bottom: 0;
        left: -200px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px 15px;
        gap: 15px;
        justify-content: space-around;
        max-width: 300px;
        transform: scale(.8);
    }
}

.social-media-icons b {
    margin-left: 10px;
}

.linked-icon {
    display: flex;
    color: white !important;
    align-items: center;
    text-decoration: none;
}

.social-media-icons .icon {
    background-color: #011142;
    color: white !important;
    display: grid;
    place-items: center;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 1px solid white;
    font-size: 15px;

    @media (min-width:992px) {
        font-size: 25px;
    }
}

.active {
    color: var(--mantine-color-white) !important;
    font-weight: bold;
    background-color: var(--mantine-color-primary-filled);
}

.active::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--mantine-color-white);
}

.hover-color {
    filter: grayscale(1);
    cursor: pointer;
    transition: all .5s;
}

.hover-color:hover {
    filter: grayscale(0);
    transition: all .5s;
}

.client-border {
    padding: 0 80px;
}

.client-border:nth-child(even) {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
}

.banner-box {
    position: relative;
    min-height: 75vh;

    @media (min-width:768px) {
        min-height: calc(100vh - 200px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

.banner-content {
    width: 100%;
    padding: 20px;
}

.banner-image-box {

    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;

    @media (max-width:768px) {
        width: 300px;
    }

    @media (min-width:768px) {
        width: 500px;
    }

    @media (min-width:992px) {
        width: 600px;
    }


    @media (min-width:1024px) {
        width: 400px;
    }

    @media (min-width:1440px) {
        width: 700px;
    }

}

.banner-image {
    width: 100%;
    filter: grayscale(1);
}

body {
    @media (max-width:1440px) {
        zoom: 91%;
    }
}

a {
    text-decoration: none;
}

#tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.banner-effect-box {
    position: absolute;
    bottom: 80px;
    z-index: 1;
    width: 300px;
    height: 200px;

    @media (max-width:768px) {
        transform: translateX(-50px) scale(.6);
        z-index: -1;
    }

    @media (min-width:768px) {
        left: -300px;
    }
}

.banner-effect,
.banner-effect-text {
    position: absolute;
    top: 0;
    left: 0;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.banner-effect {
    animation: rotate 10s linear infinite;
}

.banner-effect-text {
    left: -55px;
    top: 9px;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.text {
    position: absolute;
    font-size: 14px;
    color: #3498db;
    text-align: center;
    transform-origin: 50% 50%;
    text-wrap: nowrap;
}

.effect-container {
    position: relative;
    width: 400px;
    height: 400px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin: 50px auto;
}

.effect-container::after {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 25px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 10s linear infinite;
}


@keyframes rotation {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.text-effect-wrapper {
    position: absolute;
    z-index: 9;

    @media (max-width:768px) {
        left: -20px;
        bottom: -150px;
        transform: scale(.7);
    }

    @media (min-width:1024px) {
        right: 40%;
        bottom: -120px;
        transform: scale(.9);
    }

    @media (min-width:1149px) {
        right: 50%;
        bottom: -100px;
        transform: scale(.6);
    }

    @media (min-width:1440px) {
        right: 40%;
        bottom: -130px;
        transform: scale(1);
    }
}

.rounded-text {
    width: 150px;
    height: 150px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    position: relative;

    @media (min-width:992px) {
        transform: scale(1.3);
    }
}

.rounded-text::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 155px;
    height: 155px;
    z-index: -2;
    border: 20px solid #ffffff00;
    border-bottom-color: #dbdbdb;
    border-radius: 50%;
    animation: rotation 10s linear infinite;
}

.svg:first-child {
    -moz-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
    left: 0;
    z-index: 9;
    right: 0;
}

.svg:nth-child(2) {
    -moz-transform: scale(-1, 1);
    /* -webkit-transform: scale(-1, 1); */
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    left: 0;
    transform: translate3d(1px, 1px, 1px);
}

.svg {
    position: absolute;
    background: black;
}

.white {
    fill: #fff;
}

.yellow {
    fill: yellow;
}

.green {
    fill: lightgreen;
}

@keyframes rotating {
    0% {
        transform: rotate(360deg);
    }

    50% {
        transform: rotate(180deg);
        /* Scale up for emphasis */
    }

    100% {
        transform: rotate(0deg);
    }
}

.rotating {
    animation: rotating 25s linear infinite;
}