.featureTitle {
    color: white;
    font-weight: 400;

    @media (min-width:768px) {
        font-size: 35px;
    }
}

.featureContent {
    font-size: 12px;
    color: white;
    word-break: keep-all;

    @media (min-width:768px) {
        font-size: 16px;
    }
}