$bg-success: #42AE2D;
$gray: #919192;

.videoSliders {
    background-color: #eee;
    color: black;

    @media (min-width:992px) {
        min-width: 900px !important;
    }
}

.videoSliders-details {
    color: $gray;
}

.videoSliders-banner {
    z-index: -1;
    position: relative;

    img {
        width: 100%;
        border-radius: 10px;
        margin-top: 20px;
    }
}

.more-videoSliders {
    background-color: #333334;
    text-decoration: none;
    border-radius: 50px;
    padding: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px 15px;

    .more-videoSliders-text {
        color: white;
    }

    .more-videoSliders-icon {
        background-color: $bg-success;
        color: white;
        border-radius: 50%;
        font-size: 20px;
        margin-bottom: 3px !important;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 30px;
    }

    &:hover {
        background-color: gray;
    }
}

.logo-icon {
    width: 70px;
    height: 70px;
    overflow: hidden;

    @media (min-width:992px) {
        width: 100px;
        height: 100px;
        padding: 10px;
    }
}