.hero {
    position: relative;
    background-image: url('../../assets/images/banner.jpg');
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 250px);
}

.container {
    z-index: 1;
    position: relative;
}


.title {
    font-weight: 900;
    line-height: 35px;
    font-size: 25px;

    @media (min-width:1440px) {
        font-size: 45px;
        line-height: 1.1;
    }
}

.titleSpan {
    color: var(--mantine-color-primary-filled);
}


.description {
    max-width: 600px;
}

.control {
    margin-top: calc(var(--mantine-spacing-xl) * 1.5);
}

.featureTitle {
    color: white;
    font-weight: 400;

    @media (min-width:768px) {
        font-size: 35px;
    }
}

.featureContent {
    font-size: 12px;
    color: white;
    word-break: keep-all;

    @media (min-width:768px) {
        font-size: 16px;
    }
}

.bannerContent {
    @media (min-width: 992px) {
        margin-bottom: 150px;
    }
}

.brandLogos {
    width: 250px;
    position: absolute;
    bottom: 200px;

    @media (min-width: 992px) {
        width: 350px;
    }

    @media (max-width: 768px) {
        display: none;
    }
}

.authorImage {
    @media (min-width: 992px) {
        transform: scale(1.5) translateY(-81px);
    }
}

.marginTop100 {
    @media (min-width: 992px) {
        margin-top: 320px
    }
}