.title {
    position: relative;
    text-transform: uppercase;
    font-weight: 'Open Sans';
}

.center {
    text-align: center !important;
}

.title::after,
.title::before {
    content: '';
    position: absolute;
}

.center::after,
.center::before {
    left: 50%;
    transform: translateX(-50%);
}

.title::before {
    width: 258px;
    height: 2px;
    background: #09216B;
    border-radius: 5px;
    bottom: -7px;
}

.title::after {
    width: 141px;
    height: 8px;
    border-radius: 10px;
    background: #2A4EC9;
    bottom: -9px;
}

.right {
    text-align: end;
}

.right::after {
    right: 117px;
}

.right::before {
    right: 0;
}