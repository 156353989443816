.footer {
    padding-top: calc(var(--mantine-spacing-xl) * 2);
    background: url('../../assets/images/footer.jpg');
    border-top: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
    color: white;
    background-size: contain;
}

.description {
    font-size: 14px;
    text-align: justify;
}

.title {
    font-size: var(--mantine-font-size-lg);
    margin-bottom: calc(var(--mantine-spacing-xs) / 2);
}

.links {
    padding-left: 0;
    list-style: none;
}

.links a {
    text-decoration: none;
    color: white;
}

.linkLi {
    display: block;
    width: 200px;
    margin: auto;
    text-align: center;
    line-height: 45px;

    @media (min-width:768px) {
        text-align: left;
        line-height: 35px;
    }
}

.links a:hover,
.link:hover {
    color: var(--mantine-color-primary-filled);
}

.bgPrimary {
    background-color: var(--mantine-color-primary-filled);
    padding: 20px;
}

.link {
    color: white;
    text-decoration: none;
}

.input::placeholder {
    color: white;
}

.input {
    background-color: #f4f7f832;
    color: white;
    border: none;
    width: 295px;
    outline: none;
    margin: 10px 0;
    padding: 10px 15px;
}

.submit {
    background: linear-gradient(#2A4EC9, black);
    border: 1px solid white;
    color: white;
    outline: none;
    display: inline-flex;
    justify-self: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
}