.btnGroup {
    display: inline-flex;
    flex-wrap: wrap;
    background-color: #D9D9D9;
    border-radius: 10px;
    overflow: hidden;
    justify-content: center;
    margin: 15px;
    padding: 5px;

    @media (min-width:768px) {
        margin: 30px auto;
        padding: 10px;
    }
}

.btn.active {
    background: var(--mantine-color-primary-filled);
    border-radius: 10px;
    color: white;
}

.btn {
    color: var(--mantine-color-primary-filled);
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    padding: 10px 20px;
    min-width: 100px;
    font-size: 12px;

    @media (min-width:768px) {
        font-size: 22px;
    }
}

.imageBox {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
}

.image {
    height: 450px;
    object-fit: cover;
    filter: grayscale(1);
    transition: all .5s;
    width: calc(250px);
    object-position: center center;

    @media (max-width:768px) {
        width: calc(100% / 2 - 5px);
        height: 250px;
    }
}

.image:hover {
    filter: grayscale(0);
    transition: all .5s;
    cursor: pointer;
    transform: scale(.9);
}