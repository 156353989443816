@font-face {
    font-family: AlienAndroid;
    src: url('./assets/fonts/AlienAndroid.ttf');
}

@font-face {
    font-family: AlienAndroidItalic;
    src: url('./assets/fonts/AlienAndroidItalic.ttf');
}

body {
    overflow-x: hidden;
}