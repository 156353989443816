.header {
  width: 100%;
  min-height: 56px;
  background-color: var(--mantine-color-primary-filled);
  border-bottom: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.inner {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  display: block;
  line-height: 1;
  margin: 8px 12px;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  font-size: var(--mantine-font-size-sm);
  font-weight: lighter;
  position: relative;
  text-transform: uppercase;
  color: black;

  @media (min-width:768px) {
    color: var(--mantine-color-white);
  }

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }
}

.navLink {
  margin: 10px 0;
  font-weight: 400;
  border-radius: 5px;
  text-align: center;
}